import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useCallback, useEffect } from 'react';
import TextInput from '@magento/venia-ui/lib/components/TextInput';
import { useSearchField } from '@magento/peregrine/lib/talons/SearchBar';
import defaultClasses from './searchModal.module.css';
import { RoundSearchIcon } from '..';
import Field from '@magento/venia-ui/lib/components/Field';
import { SEARCH_TERM } from '../../graphql/magefan.gql';
import { useMutation } from '@apollo/client';

var SearchField = function SearchField(_ref) {
  var isSearchOpen = _ref.isSearchOpen,
      closeModal = _ref.closeModal,
      onChange = _ref.onChange,
      onFocus = _ref.onFocus,
      klevuValue = _ref.value,
      functionToReset = _ref.functionToReset,
      setFunctionToReset = _ref.setFunctionToReset,
      classes = _ref.classes;

  var _useSearchField = useSearchField({
    isSearchOpen: isSearchOpen
  }),
      inputRef = _useSearchField.inputRef,
      resetForm = _useSearchField.resetForm,
      value = _useSearchField.value,
      formApi = _useSearchField.formApi;

  var values = formApi.getValues();

  var _useMutation = useMutation(SEARCH_TERM),
      _useMutation2 = _slicedToArray(_useMutation, 1),
      search = _useMutation2[0];

  useEffect(function () {
    if (klevuValue) search({
      variables: {
        searchTerm: klevuValue
      }
    })["catch"](function (err) {
      return console.error(err);
    });
  }, [klevuValue]);
  useEffect(function () {
    if (value === undefined && klevuValue !== undefined) {
      onChange(value);
      formApi.setValue('search_query', value);
    }

    if (klevuValue === undefined && value !== undefined) {
      onChange(klevuValue);
      formApi.setValue('search_query', klevuValue);
    }

    if (functionToReset === undefined) {
      setFunctionToReset({
        api: formApi,
        klevuSearch: onChange
      });
    }

    if (klevuValue) {
      formApi.setValue('search_query', klevuValue);
    }
  }, [value, klevuValue, functionToReset]);
  var resetSearchQuery = useCallback(function () {
    formApi.setValue('search_query', '');
    onChange('');
  }, [value, klevuValue]);
  return React.createElement(Field, {
    id: "search_query",
    label: "search_query"
  }, React.createElement(TextInput, {
    after: React.createElement(RoundSearchIcon, null) // before={SearchIcon}
    ,
    field: "search_query",
    onFocus: onFocus // onValueChange={onChange}
    ,
    onChange: onChange,
    forwardedRef: inputRef,
    placeholder: "Search for",
    classes: {
      input: defaultClasses.searchField,
      placeholder: defaultClasses.searchFieldPlaceholder
    }
  }), klevuValue !== null && klevuValue !== void 0 && klevuValue.length ? React.createElement("div", {
    className: defaultClasses.resetLink,
    onClick: resetSearchQuery
  }, "Reset Search") : React.createElement(React.Fragment, null));
};

export default SearchField;