import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import mergeOperations from '@magento/peregrine/lib/util/shallowMerge';
import { useEventingContext } from '@magento/peregrine/lib/context/eventing';
import DEFAULT_OPERATIONS from './categoryContent.gql';
import { useWindowSize } from '@magento/peregrine';
/**
 * Returns props necessary to render the categoryContent component.
 *
 * @param {object} props.data - The results of a getCategory GraphQL query.
 *
 * @returns {object} result
 * @returns {string} result.categoryDescription - This category's description.
 * @returns {string} result.categoryName - This category's name.
 * @returns {object} result.filters - The filters object.
 * @returns {object} result.items - The items in this category.
 * @returns {number} result.totalPagesFromData - The total amount of pages for the query.
 */

export var useCategoryContent = function useCategoryContent(props) {
  var _categoryData$categor, _categoryData$categor2, _categoryData$categor3, _categoryData$categor4, _categoryData$categor5, _categoryData$categor6;

  var categoryId = props.categoryId,
      data = props.data,
      _props$pageSize = props.pageSize,
      pageSize = _props$pageSize === void 0 ? 6 : _props$pageSize;
  var operations = mergeOperations(DEFAULT_OPERATIONS, props.operations);
  var getCategoryContentQuery = operations.getCategoryContentQuery,
      getProductFiltersByCategoryQuery = operations.getProductFiltersByCategoryQuery,
      getCategoryAvailableSortMethodsQuery = operations.getCategoryAvailableSortMethodsQuery;
  var placeholderItems = Array.from({
    length: pageSize
  }).fill(null);

  var _useLazyQuery = useLazyQuery(getProductFiltersByCategoryQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  }),
      _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
      getFilters = _useLazyQuery2[0],
      filterData = _useLazyQuery2[1].data;

  var windowSize = useWindowSize();
  var isTablet = windowSize.innerWidth <= 1024;

  var _useState = useState(4),
      _useState2 = _slicedToArray(_useState, 2),
      gridCols = _useState2[0],
      setGridCols = _useState2[1];

  useEffect(function () {
    return isTablet && gridCols === 4 && setGridCols(3);
  }, [gridCols, isTablet]);

  var _useLazyQuery3 = useLazyQuery(getCategoryAvailableSortMethodsQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  }),
      _useLazyQuery4 = _slicedToArray(_useLazyQuery3, 2),
      getSortMethods = _useLazyQuery4[0],
      sortData = _useLazyQuery4[1].data;

  var _useQuery = useQuery(getCategoryContentQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !categoryId,
    variables: {
      id: categoryId
    }
  }),
      categoryData = _useQuery.data,
      categoryLoading = _useQuery.loading;

  var _useEventingContext = useEventingContext(),
      _useEventingContext2 = _slicedToArray(_useEventingContext, 2),
      dispatch = _useEventingContext2[1].dispatch; // useEffect(() => {
  //     if (categoryId) {
  //         getFilters({
  //             variables: {
  //                 categoryIdFilter: {
  //                     eq: categoryId
  //                 }
  //             }
  //         });
  //     }
  // }, [categoryId, getFilters]);
  // useEffect(() => {
  //     if (categoryId) {
  //         getSortMethods({
  //             variables: {
  //                 categoryIdFilter: {
  //                     in: categoryId
  //                 }
  //             }
  //         });
  //     }
  // }, [categoryId, getSortMethods]);


  var filters = filterData ? filterData.products.aggregations : null;
  var items = data ? data.products.items : placeholderItems;
  var totalPagesFromData = data ? data.products.page_info.total_pages : null;
  var totalCount = data ? data.products.total_count : null;
  var category = categoryData && categoryData.categories.items.length ? (_categoryData$categor = categoryData.categories.items) === null || _categoryData$categor === void 0 ? void 0 : _categoryData$categor[0] : null;
  var categoryName = category ? category.name : null;
  var categoryDescription = category ? category.description : null;
  var availableSortMethods = sortData ? sortData.products.sort_fields.options : null;
  useEffect(function () {
    if (!categoryLoading && categoryData.categories.items.length > 0) {
      dispatch({
        type: 'CATEGORY_PAGE_VIEW',
        payload: {
          id: category.uid,
          name: category.name,
          url_key: category.url_key,
          url_path: category.url_path
        }
      });
    }
  }, [category, dispatch, categoryLoading]);
  var displayMode = categoryData ? (_categoryData$categor2 = categoryData.categories) === null || _categoryData$categor2 === void 0 ? void 0 : (_categoryData$categor3 = _categoryData$categor2.items) === null || _categoryData$categor3 === void 0 ? void 0 : (_categoryData$categor4 = _categoryData$categor3[0]) === null || _categoryData$categor4 === void 0 ? void 0 : _categoryData$categor4.display_mode : null; // The client decided against landing pages with listing of subcategories, so this is changing to
  // be always false but keeping all other logic in case the clients changes his mind or maybe
  // we can even use this logic to show landing pages based on displayMode

  var subCategories = categoryData ? (_categoryData$categor5 = categoryData.categories) === null || _categoryData$categor5 === void 0 ? void 0 : (_categoryData$categor6 = _categoryData$categor5.items) === null || _categoryData$categor6 === void 0 ? void 0 : _categoryData$categor6[0].children : null;
  return {
    availableSortMethods: availableSortMethods,
    categoryName: categoryName,
    categoryDescription: categoryDescription,
    category: category,
    filters: filters,
    items: items,
    totalCount: totalCount,
    totalPagesFromData: totalPagesFromData,
    displayMode: displayMode,
    subCategories: subCategories,
    gridCols: gridCols,
    setGridCols: setGridCols
  };
};