import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import defaultClasses from './rowItem.module.css';
import CollectionSlider from './collectionSlider';
import ProductItem from './productItem';
import { useWindowSize } from '@magento/peregrine';

var RowItem = function RowItem(_ref) {
  var items = _ref.items,
      outletPriceRange = _ref.outletPriceRange,
      isCollectionBtnAllowed = _ref.isCollectionBtnAllowed,
      storeConfig = _ref.storeConfig,
      downloadImages = _ref.downloadImages,
      refetch = _ref.refetch,
      isUltraSmallDevice = _ref.isUltraSmallDevice,
      isFiltersHidden = _ref.isFiltersHidden,
      handleCloseAllCollections = _ref.handleCloseAllCollections,
      closeAllCollections = _ref.closeAllCollections,
      crosssellData = _ref.crosssellData,
      productMarkerId = _ref.productMarkerId,
      persistScrollPosition = _ref.persistScrollPosition,
      handleProductScroll = _ref.handleProductScroll,
      isProductScrolled = _ref.isProductScrolled,
      favoritesData = _ref.favoritesData,
      refetchFavorites = _ref.refetchFavorites,
      numberItemsInRow = _ref.numberItemsInRow;
  var location = useLocation();
  var windowSize = useWindowSize();
  var isMobile = windowSize.innerWidth <= 768;
  var isTabletDevice = windowSize.innerWidth > 768 && windowSize.innerWidth < 1279;

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      itemCollection = _useState2[0],
      setItemCollection = _useState2[1];

  var _useState3 = useState(null),
      _useState4 = _slicedToArray(_useState3, 2),
      itemPosition = _useState4[0],
      setItemPosition = _useState4[1];

  var handleSetItemCollection = useCallback(setItemCollection, [setItemCollection]);
  var collectionRef = useRef();
  var toggleCollection = useCallback(function (item, idx) {
    if ((item === null || item === void 0 ? void 0 : item.sku) === (itemCollection === null || itemCollection === void 0 ? void 0 : itemCollection.sku)) {
      handleSetItemCollection(null);
      setItemPosition(null);
      handleCloseAllCollections(item);
    } else if (item !== null && item !== void 0 && item.crosssell_products) {
      handleSetItemCollection(_objectSpread({}, item));
      setItemPosition(idx + 1);
      handleCloseAllCollections(item);
    }
  }, [itemCollection, handleSetItemCollection, closeAllCollections, handleCloseAllCollections]);
  useEffect(function () {
    if (closeAllCollections && itemCollection) {
      var hasItem = items.some(function (item) {
        return item.sku === closeAllCollections.sku;
      });

      if (!hasItem) {
        setItemCollection(null);
      }
    }
  }, [closeAllCollections, itemCollection]);
  useEffect(function () {
    return handleSetItemCollection(null);
  }, [location === null || location === void 0 ? void 0 : location.pathname, location === null || location === void 0 ? void 0 : location.search]);
  var content = useMemo(function () {
    return items === null || items === void 0 ? void 0 : items.map(function (item, idx) {
      var _favoritesData$existI, _favoritesData$existI2;

      var handleLinkClick = function handleLinkClick() {
        item === null || item === void 0 ? void 0 : item.clickManager(item === null || item === void 0 ? void 0 : item.id, item === null || item === void 0 ? void 0 : item.categoryTitle, item === null || item === void 0 ? void 0 : item.abTestVariantId);
      };

      var collectionVisible = (itemCollection === null || itemCollection === void 0 ? void 0 : itemCollection.sku) === (item === null || item === void 0 ? void 0 : item.sku);
      var priceRange = outletPriceRange === null || outletPriceRange === void 0 ? void 0 : outletPriceRange.filter(function (outletItem) {
        return outletItem.sku === (item === null || item === void 0 ? void 0 : item.sku);
      })[0];
      var imageItem = crosssellData === null || crosssellData === void 0 ? void 0 : crosssellData.find(function (cData) {
        return (cData === null || cData === void 0 ? void 0 : cData.sku) == (item === null || item === void 0 ? void 0 : item.sku);
      });
      return React.createElement(ProductItem, {
        key: item.id,
        item: item,
        priceRange: priceRange,
        isFiltersHidden: isFiltersHidden,
        index: idx,
        toggleCollection: toggleCollection,
        collectionVisible: collectionVisible,
        storeConfig: storeConfig,
        refetch: refetch,
        downloadImages: downloadImages,
        isCollectionBtnAllowed: isCollectionBtnAllowed,
        handleLinkClick: handleLinkClick,
        imageItem: imageItem,
        productMarkerId: productMarkerId,
        persistScrollPosition: persistScrollPosition,
        handleProductScroll: handleProductScroll,
        isProductScrolled: isProductScrolled,
        isInFavorites: (favoritesData === null || favoritesData === void 0 ? void 0 : (_favoritesData$existI = favoritesData.existInFavorites) === null || _favoritesData$existI === void 0 ? void 0 : (_favoritesData$existI2 = _favoritesData$existI.find(function (x) {
          var _item$id;

          return item === null || item === void 0 ? void 0 : (_item$id = item.id) === null || _item$id === void 0 ? void 0 : _item$id.startsWith(x === null || x === void 0 ? void 0 : x.product_id);
        })) === null || _favoritesData$existI2 === void 0 ? void 0 : _favoritesData$existI2.exist) || false,
        refetchFavorites: refetchFavorites
      });
    });
  }, [refetchFavorites, favoritesData, itemCollection === null || itemCollection === void 0 ? void 0 : itemCollection.sku, items, outletPriceRange]);
  var collection = itemCollection && React.createElement(CollectionSlider, {
    isUltraSmallDevice: isUltraSmallDevice,
    collection: itemCollection,
    itemPosition: itemPosition,
    isMobile: isMobile,
    isTabletDevice: isTabletDevice,
    numberItemsInRow: numberItemsInRow
  });
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "".concat(defaultClasses.root, " products-row"),
    style: {
      gridTemplateColumns: isMobile ? "repeat(2, 1fr)" : "repeat(".concat(numberItemsInRow, ", 1fr)")
    }
  }, content, isMobile ? null : collection), isMobile ? collection : null);
};

export default RowItem;