// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".productsListheader-productsListHeader-peu {\n}\n\n.productsListheader-sidebarWrapper-Yg- {\n    width: 25%;\n}\n\n.productsListheader-sidebarWrapper_active-gpX {\n}\n\n.productsListheader-productsListWrapper-IbS {\n    width: 85%;\n}\n\n.productsListheader-dropdownWrapper-jPc {\n    width: 234px;\n}\n\n.productsListheader-productSort-Vcg {\n    width: 234px;\n}\n\n.productsListheader-gridColsIcon-6vU {\n    width: 1.75rem;\n    height: 1.75rem;\n}\n\n@media (max-width: 767px) {\n    .productsListheader-dropdownWrapper-jPc {\n        width: 200px;\n    }\n\n    .productsListheader-productSort-Vcg {\n        width: 200px;\n        position: relative;\n    }\n\n    .productsListheader-productSort-Vcg > div:nth-child(2) {\n        width: 200px;\n        position: absolute;\n        right: 0;\n    }\n}\n\n@media (max-width: 400px) {\n    .productsListheader-productSort-Vcg > div + div {\n        border-top: 1px solid var(--venia-global-color-urbane-bronze);\n    }\n\n    .productsListheader-dropdownWrapper-jPc {\n        width: 109px;\n    }\n\n    .productsListheader-productSort-Vcg {\n        width: 109px;\n    }\n\n    .productsListheader-productSort-Vcg > div:nth-child(2) {\n        width: 200px;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productsListHeader": "productsListheader-productsListHeader-peu mt-16 mb-7 flex",
	"sidebarWrapper": "productsListheader-sidebarWrapper-Yg- flex items-center ml-0",
	"sidebarWrapper_active": "productsListheader-sidebarWrapper_active-gpX productsListheader-sidebarWrapper-Yg- flex items-center ml-0",
	"productsListWrapper": "productsListheader-productsListWrapper-IbS flex justify-between",
	"dropdownWrapper": "productsListheader-dropdownWrapper-jPc",
	"productSort": "productsListheader-productSort-Vcg",
	"gridColsIcon": "productsListheader-gridColsIcon-6vU"
};
export default ___CSS_LOADER_EXPORT___;
