import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

import { gql } from '@apollo/client';
export var ORDER_BEGIN = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    mutation orderBegin($cartId: String!) {\n        orderBegin(cartId: $cartId) {\n            status\n        }\n    }\n"])));
export var SEARCH_TERM = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    mutation search($searchTerm: String!) {\n        search(searchTerm: $searchTerm) {\n            status\n        }\n    }\n"])));
export var VIEW_ITEM = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    mutation viewItem($productId: Int!) {\n        viewItem(productId: $productId) {\n            status\n        }\n    }\n"])));
export var VIEW_ITEM_LIST = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    mutation viewItemList($productIdentifiers: String!, $listType: String!) {\n        viewItemList(productIdentifiers: $productIdentifiers, listType: $listType) {\n            status\n        }\n    }\n"])));
export var VIEW_CART = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    mutation viewCart($cartId: String!) {\n        viewCart(cartId: $cartId) {\n            status\n        }\n    }\n"])));
export var ADD_SHIPPING_INFO = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    mutation addShippingInfo($cartId: String!, $shippingMethod: String!) {\n        addShippingInfo(cartId: $cartId, shippingMethod: $shippingMethod) {\n            status\n        }\n    }\n"])));
export var ADD_BILLING_INFO = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    mutation addBillingInfo($cartId: String!, $paymentMethod: String!) {\n        addBillingInfo(cartId: $cartId, paymentMethod: $shippingMethod) {\n            status\n        }\n    }\n"])));