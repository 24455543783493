import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState, useRef, useCallback } from 'react';
import { useWindowSize } from '@magento/peregrine/lib/hooks/useWindowSize';
import { useFilterSidebar } from '@magento/peregrine/lib/talons/FilterSidebar';
import Trigger from '@magento/venia-ui/lib/components/Trigger';
import { useFilterModal } from '@magento/peregrine/lib/talons/FilterModal';
import { KlevuDomEvents } from '@klevu/core';
import classes from './productsListheader.module.css';
import { SidebarIcon, CurrentFilters, Pagination, ProductSort, ProductSortShimmer } from '../../components';
import Grid3Inactive from '../../assets/grid-3.png';
import Grid4Inactive from '../../assets/grid-4.png';
import Grid3Active from '../../assets/grid-3-active.png';
import Grid4Active from '../../assets/grid-4-active.png';
var SCROLL_OFFSET = 150;

var ProductsListHeader = function ProductsListHeader(_ref) {
  var toggleFiltersSidebar = _ref.toggleFiltersSidebar,
      isFiltersHidden = _ref.isFiltersHidden,
      filters = _ref.filters,
      pageControl = _ref.pageControl,
      totalCount = _ref.totalCount,
      isLoading = _ref.isLoading,
      totalPagesFromData = _ref.totalPagesFromData,
      sortProps = _ref.sortProps,
      klevuSortProps = _ref.klevuSortProps,
      availableSortMethods = _ref.availableSortMethods,
      useKlevuSort = _ref.useKlevuSort,
      klevuFilterManager = _ref.klevuFilterManager,
      pageSize = _ref.pageSize,
      setPageSize = _ref.setPageSize,
      pageSizeValues = _ref.pageSizeValues,
      _ref$gridCols = _ref.gridCols,
      gridCols = _ref$gridCols === void 0 ? '' : _ref$gridCols,
      _ref$setGridCols = _ref.setGridCols,
      setGridCols = _ref$setGridCols === void 0 ? function () {} : _ref$setGridCols;

  // const [selectedOption, setSelectedOption] = useState(null);
  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isFilterSToggled = _useState2[0],
      toggleFilters = _useState2[1];

  var _useWindowSize = useWindowSize(),
      innerWidth = _useWindowSize.innerWidth;

  var isSmallDevice = innerWidth <= 1024;
  var isLaptop = innerWidth <= 1440;

  var _useFilterModal = useFilterModal({
    filters: filters
  }),
      handleOpen = _useFilterModal.handleOpen;

  var _useFilterSidebar = useFilterSidebar({
    filters: filters
  }),
      filterApi = _useFilterSidebar.filterApi,
      filterItems = _useFilterSidebar.filterItems,
      filterNames = _useFilterSidebar.filterNames,
      filterState = _useFilterSidebar.filterState,
      handleApply = _useFilterSidebar.handleApply,
      handleReset = _useFilterSidebar.handleReset,
      AddFilter = _useFilterSidebar.AddFilter;

  var handleResetFn = function handleResetFn() {
    klevuFilterManager.clearOptionSelections();
    handleReset();
    var event = new Event(KlevuDomEvents.FilterSelectionUpdate);
    dispatchEvent(event);
  }; // If there are no products we can hide the sort button.


  var shouldShowSortButtons = totalPagesFromData;
  var shouldShowSortShimmer = !totalPagesFromData && isLoading;
  var filterRef = useRef(); // const handleOptionClick = value => setSelectedOption(value);

  var handleToggleDropdown = function handleToggleDropdown() {
    return toggleFilters(function (prevState) {
      return !prevState;
    });
  };

  var handleApplyFilter = useCallback(function () {
    var filterElement = filterRef.current;

    if (filterElement && typeof filterElement.getBoundingClientRect === 'function') {
      var filterTop = filterElement.getBoundingClientRect().top;
      var windowScrollY = window.scrollY + filterTop - SCROLL_OFFSET;
      window.scrollTo(0, windowScrollY);
    }

    AddFilter.apply(void 0, arguments);
    handleApply.apply(void 0, arguments);
  }, [handleApply, filterRef]);
  var toggleSidebarIcon = React.createElement(Trigger, {
    action: isSmallDevice ? handleOpen : toggleFiltersSidebar,
    "aria-label": "".concat(!isFiltersHidden ? 'Hide' : 'Show', " filter sidebar")
  }, React.createElement("span", {
    className: "sr-only"
  }, "Filter sidebar icon"), React.createElement(SidebarIcon, null));
  var sortDropdown = shouldShowSortButtons ? React.createElement(ProductSort, {
    sortProps: sortProps,
    classes: {
      root: classes.productSort
    },
    klevuSortProps: klevuSortProps,
    availableSortMethods: availableSortMethods,
    useKlevuSort: useKlevuSort
  }) : shouldShowSortShimmer ? React.createElement(ProductSortShimmer, null) : null;
  return React.createElement(React.Fragment, null, isSmallDevice ? React.createElement("div", {
    className: "mt-8"
  }, React.createElement("div", {
    className: " flex justify-between pb-5 border-b border-solid border-gray "
  }, React.createElement("div", {
    className: "flex items-center"
  }, React.createElement("h6", {
    className: "mr-1.5"
  }, isFiltersHidden ? 'Hide' : 'Show', " Filters"), toggleSidebarIcon), sortDropdown), React.createElement("div", {
    className: "my-5"
  }, totalCount, " Results")) : React.createElement(React.Fragment, null, React.createElement("div", {
    className: classes.productsListHeader
  }, React.createElement("div", {
    className: isFiltersHidden ? classes.sidebarWrapper : classes.sidebarWrapper_active
  }, toggleSidebarIcon, React.createElement("h6", {
    className: "ml-3 mr-8"
  }, isFiltersHidden ? 'Show' : 'Hide', " Filters")), React.createElement("div", {
    className: classes.productsListWrapper
  }, React.createElement("div", {
    className: "flex"
  }, sortDropdown, (filterState === null || filterState === void 0 ? void 0 : filterState.size) > 0 && React.createElement(CurrentFilters, {
    isFilterSToggled: isFilterSToggled,
    handleToggleDropdown: handleToggleDropdown,
    filterState: filterState,
    filterApi: filterApi,
    onRemove: handleApplyFilter,
    handleReset: handleResetFn,
    klevuFilterManager: klevuFilterManager
  })), React.createElement("div", {
    className: "flex ".concat(isLaptop ? 'gap-1' : 'gap-4')
  }, React.createElement(Pagination, {
    pageSize: pageSize,
    setPageSize: setPageSize,
    pageSizeValues: pageSizeValues,
    isLoading: isLoading,
    pageControl: pageControl,
    totalCount: totalCount,
    isPlp: true
  }), !isSmallDevice && React.createElement("div", {
    className: "flex gap-2 ml-2"
  }, React.createElement("button", {
    onClick: function onClick() {
      return setGridCols(3);
    }
  }, React.createElement("img", {
    className: classes.gridColsIcon,
    src: gridCols === 3 ? Grid3Active : Grid3Inactive,
    alt: "grid-cols-3-icon"
  })), React.createElement("button", {
    onClick: function onClick() {
      return setGridCols(4);
    }
  }, React.createElement("img", {
    className: classes.gridColsIcon,
    src: gridCols === 4 ? Grid4Active : Grid4Inactive,
    alt: "grid-cols-4-icon"
  }))))))));
};

export default ProductsListHeader;