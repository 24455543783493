// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".breadcrumbs-root-MTL {\n    /* Permanent height to prevent pop-in. */\n    /* composes: h-[2.5rem] from global; */\n    /* Show ellipses when overflowing. */\n    /* composes: pt-[0.625rem] from global; */\n    /* composes: pb-[1rem] from global; */\n    /* composes: -mb-[1rem] from global; */\n}\n\n.breadcrumbs-text-6NW {\n}\n\n.breadcrumbs-divider-Ilp {\n}\n\n.breadcrumbs-link-X16 {\n}\n\n.breadcrumbs-currentCategory-pBn {\n}\n\n.breadcrumbs-currentCategoryTitle-fkN {\n}\n\n@media screen and (max-width: 1440px) {\n    .breadcrumbs-currentCategoryTitle-fkN {\n        font-size: 2.25rem !important;\n    }\n}\n\n@media screen and (max-width: 1023px) {\n    .breadcrumbs-root-MTL::-webkit-scrollbar {\n        height: 2px;\n    }\n}\n\n@media screen and (max-width: 991px) {\n    .breadcrumbs-root-MTL::-webkit-scrollbar-track {\n        box-shadow: inset 0 0 0.1rem var(--venia-global-color-snowbound);\n        border-radius: 0.1rem;\n    }\n\n    .breadcrumbs-root-MTL::-webkit-scrollbar-thumb {\n        background: var(--venia-global-color-snowbound);\n        border-radius: 0.5rem;\n        scrollbar-width: thin;\n    }\n\n    /* .root::-webkit-scrollbar-thumb:hover {\n        background: transparent;\n    } */\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "breadcrumbs-root-MTL lg_overflow-ellipsis overflow-auto overflow-x-scroll lg_overflow-x-hidden overflow-hidden text-sm whitespace-nowrap",
	"text": "breadcrumbs-text-6NW p-1 text-black",
	"divider": "breadcrumbs-divider-Ilp breadcrumbs-text-6NW p-1 text-black",
	"link": "breadcrumbs-link-X16 breadcrumbs-text-6NW p-1 text-black underline",
	"currentCategory": "breadcrumbs-currentCategory-pBn breadcrumbs-text-6NW p-1 text-black",
	"currentCategoryTitle": "breadcrumbs-currentCategoryTitle-fkN mt-5"
};
export default ___CSS_LOADER_EXPORT___;
